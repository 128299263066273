/*eslint no-unused-vars: "off"*/

import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { useIntl } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form/standalone'
// import { DisplayFormikState } from "./../formikHelper"
import { colors as ConfigSharedColors } from './../../s4n/Config/Shared'


// @see: https://stackoverflow.com/questions/49535551/change-secondary-and-primary-colors-in-material-ui
// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: ConfigSharedColors.primary
    },
    secondary: {
      main: ConfigSharedColors.secondary
    }
  }
});

const styles = theme => ({
  textField: {
    root: {
      // color: `#fff`,
    },
    input: {
      // color: `#fff`,
    },
  },
  input: {
    // color: `#fff`,
  },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});



const FormStateless = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const { classes } = props;

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
    agreement: false,
    agreement2: false,
  });

  const formSchema = {}

  let initialValues = {
    subject: 'Wiadomość ze strony pokojewstarejkarczmie.pl',
    name: '',
    email: '',
    phone: '',
    // company: '',
    message: '',
    agreement: false,
    agreement2: false,
  }

  // require to provide at least one contact field e-mail or phone 
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    message: Yup.string().required(),
    agreement: Yup.boolean().oneOf([true], t({ id: "soft4net_form_validation_message_boolean_oneOf" })),
    agreement2: Yup.boolean().oneOf([true], t({ id: "soft4net_form_validation_message_boolean_oneOf" })),
  })

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = 'contact_s4n';
  // oFormHelper.formEndpoint = '';

  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  // useEffect(() => {
  //   // Form schema does not contain markup elements!
  //   oFormHelper.getFormSchema(initialValues.webform_id);
  // }, []);

  const handleChangeCheckbox = name => event => {
    setState((prevState, props) => ({
        ...prevState,
        [name]: event.target.checked
    }));
  };

  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        {oFormHelper.state.loading ? // or state.loading this is a reference
          <Preloader /> : 
          <Formik
            initialValues={initialValues}
            onSubmit={oFormHelper.onSubmit}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                errors,
                status,
                touched,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
              } = props;

              // console.log(status)

              const formStatusClass = classNames({
                // "mb-0": true,
                "alert": true,
                "d-none": null === formStatus.class,
                [`alert-${formStatus.class}`]: true,
              });

              const formButtonClass = classNames({
                "btn btn-primary": true,
                "green": formStatus && formStatus.success,
              });

              return (
                <Form 
                    onSubmit={handleSubmit} 
                    className={initialValues.webform_id}
                >
                    <div className="row flex-column">
                        <div className="col">
                            {/* <input type="text" id="name" name="name" placeholder="Full Name" className="form-control px-0 _mb-4" /> */}
                            <TextField 
                              id={`${initialValues.webform_id}_name`}
                              label={ oFormHelper.getFieldTitle(`name`) }
                              name="name"
                              error={errors.name && touched.name}
                              className={`${classes.textField} form-control px-0 _mb-4`}
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.name && touched.name) && errors.name}
                              margin="normal"
                              InputProps={{
                                className: classes.input
                              }}
                            />
                        </div>
                        <div className="col">
                            {/* <input type="email" id="email" name="email" placeholder="Email Address" className="form-control px-0 _mb-4" /> */}
                            <TextField
                              id={`${initialValues.webform_id}_email`}
                              label={ oFormHelper.getFieldTitle(`email`) }
                              name="email"
                              error={errors.email && touched.email}
                              className={`${classes.textField} form-control px-0 _mb-4`}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.email && touched.email) && errors.email}
                              margin="normal"
                              InputProps={{
                                className: classes.input
                              }}
                            />
                        </div>
                        <div className="col">
                            {/* <input type="email" id="email" name="email" placeholder="Email Address" className="form-control px-0 _mb-4" /> */}
                            <TextField
                              id={`${initialValues.webform_id}_phone`}
                              label={ oFormHelper.getFieldTitle(`phone`) }
                              name="phone"
                              error={errors.phone && touched.phone}
                              className={`${classes.textField} form-control px-0 _mb-4`}
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.phone && touched.phone) && errors.phone}
                              margin="normal"
                              InputProps={{
                                className: classes.input
                              }}
                            />
                        </div>
                        {/* <div className="col">
                            <TextField
                              id={`${initialValues.webform_id}_company`}
                              label={ oFormHelper.getFieldTitle(`company`) }
                              name="company"
                              error={errors.company && touched.company}
                              className={`${classes.textField} form-control px-0 _mb-4`}
                              value={values.company}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.company && touched.company) && errors.company}
                              margin="normal"
                              InputProps={{
                                className: classes.input
                              }}
                            />
                        </div> */}
                        <div className="col">
                            {/* <textarea name="message" id="message" className="form-control px-0 _mb-4" placeholder="Type Message Here"></textarea> */}
                            <TextField
                              id={`${initialValues.webform_id}_message`}
                              label={ oFormHelper.getFieldTitle(`message`) }
                              name="message"
                              error={errors.message && touched.message}
                              className={`${classes.textField} form-control px-0 _mb-4`}
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.message && touched.message) && errors.message}
                              margin="normal"
                              InputProps={{
                                className: classes.input
                              }}

                              // display as textarea
                              multiline={true}
                              minRows="5"
                              maxRows="5"
                            />
                        </div>
                        <div className="col">
                          <FormControlLabel
                            className={`agreement`}
                            control={
                              <Checkbox id="agreement" name="agreement" checked={state.agreement} value={state.agreement}
                                onClick={handleChangeCheckbox('agreement')}
                                onChange={handleChange} 
                                color="primary"
                              />}
                            // label={ t({ id: "soft4net_form_label_agreement" }) }
                            // dangerouslySetInnerHTML={{ __html: t({ id: "soft4net_form_label_agreement" }) }}
                            // htmlFor={{ __html: t({ id: "soft4net_form_label_agreement" }) }}
                            label={<span dangerouslySetInnerHTML={{ __html: t({ id: "soft4net_form_label_agreement" }) }}></span>}
                            // color="primary"
                          />
                          {errors.agreement && 
                            // touched.agreement &&
                              <p className="MuiFormHelperText-root Mui-error">{errors.agreement}</p>
                          }
                        </div>
                        <div className="col">
                          <FormControlLabel
                            className={`agreement`}
                            control={
                              <Checkbox id="agreement2" name="agreement2" checked={state.agreement2} value={state.agreement2}
                                onClick={handleChangeCheckbox('agreement2')}
                                onChange={handleChange} 
                                color="primary"
                              />}
                            // label={ t({ id: "soft4net_form_label_agreement" }) }
                            // dangerouslySetInnerHTML={{ __html: t({ id: "soft4net_form_label_agreement" }) }}
                            // htmlFor={{ __html: t({ id: "soft4net_form_label_agreement" }) }}
                            label={<span dangerouslySetInnerHTML={{ __html: t({ id: "soft4net_form_label_agreement2" }) }}></span>}
                            // color="primary"
                          />
                          {errors.agreement2 && 
                            // touched.agreement2 &&
                              <p className="MuiFormHelperText-root Mui-error">{errors.agreement2}</p>
                          }
                        </div>
                        <div className="col mx-auto mt-4">
                            {/* <button className="btn btn-primary w-100">Send</button> */}

                            <DialogActions>
                              {isSubmitting ? 
                                <CircularProgress /> : 
                                <Button 
                                  type="submit" 
                                  variant="contained" 
                                  color="primary"
                                  className={formButtonClass}
                                  disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                              }
                            </DialogActions>

                        </div>
                    </div>

                    {/* Form status messages */}
                    <div className="row form-status">
                      <div className="col">
                        <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert"></div>
                      </div>
                    </div>

                    {/* <DisplayFormikState {...props} /> */}

                </Form>
              );
            }}
          </Formik>
        }
        </React.Fragment>
      </MuiThemeProvider>
    )
}

export default withStyles(styles)(FormStateless)